import React from "react";
import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "hsl(0,0%,70%)" : "hsl(0,0%,70%)"
    }
  }),
};

const Autocomplete = (props) => (
  <div className={'searchable-dropdown'}>
    {props.billingInfo ? <Select
      value={props.companyOrState ? props.options.filter((option) => option.value === props.defaultValue) : props.options.filter((option) => option.value == props.defaultValue) || props.defaultValue}
      options={props.options}
      onChange={(option) => props.companyOrState ? props.changeHandler(option.value, option.states, props.companyStatus) : props.changeHandler(option.value, props.companyStatus)}
      classNamePrefix={'menu-item'}
      styles={customStyles}
      isDisabled={props.disabled ? props.disabled : false}
    />:<Select
      value={props.defaultValue && props.options.filter((option) => option.value === props.defaultValue)}
      options={props.options}
      onChange={(option) => props.changeHandler(props.assignedState, option.value)}
      classNamePrefix={'menu-item'}
      styles={customStyles}
      isDisabled={props.disabled ? props.disabled : false}
    />}
  </div>
);
Autocomplete.defaultProps = {
  billingInfo: false
}
Autocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  assignedState: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any.isRequired,
  billingInfo: PropTypes.bool,
  companyStatus: PropTypes.number,
  companyOrState: PropTypes.bool
};

export default Autocomplete;