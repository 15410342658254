/* eslint-disable no-undef*/
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StyledModal, CustomTitle, ContentWrapper, NoGuests, AddBuddyButtonContainer, /*AddBuddyButtons*/ } from './styles';
import CSVList from './CSVList';
import Image from '../Image';
import { sendFriendRequest } from '../../redux/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ImageUrl } from '../../utils/constants';

const headers = [
  { label: "First Name", key: "fname" },
  { label: "Last Name", key: "lname" },
  { label: "Email", key: "EMAIL" },
  { label: "Company Name", key: "company_name" },
];

class AttendeesModal extends Component {
  constructor() {
    super();
    this.state = {
      marginLeft: false,
      printAttendees: false,
    }
  }

  componentDidMount() {
    setTimeout(this.setState({ marginLeft: true }), 3000)
  }

  printAction = (bool) => {
    this.setState({
      printAttendees: bool
    })
  }

  render() {
    const { Attendees, showModal, onHide, Title, /*isValidUser, sendFriendRequest,*/ t, /*role, eventType, eventAttendees*/ } = this.props;
    const { marginLeft, printAttendees } = this.state;
    return (
      <StyledModal show={showModal} onHide={() => onHide()}>
        <Modal.Header style={{background:"#005c87"}}>
          <CustomTitle>
            <div>
              <span>{t("Registered")}</span>
              <span className="closeImage">
                <div className="print" onClick={() => this.printAction(true)}>
                  <img src={ImageUrl+'/images/downloadIcon.png'} />
                </div>
                <img src='/public/images/new_close_icon.png' onClick={() => onHide()} /></span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          {
            Attendees.length > 0 ?
              Attendees.map((attendee, index) => (
                <ContentWrapper key={index} marginLeft={marginLeft}>
                  <div>
                    <div className="image">
                      <Image image={attendee.profile_image} alt={attendee.full_name} />
                    </div>
                    <div className="attendeeInfo">
                      <span>{`${attendee.fname} ${attendee.lname}`}</span>
                      <p>{t(attendee.department)}</p>
                    </div>
                    <AddBuddyButtonContainer background={'#69c2ff'}>
                      {/* {
                        attendee.request_status === null ?
                          <AddBuddyButtons
                            onClick={() => sendFriendRequest(attendee.uid)} background={'rgb(253, 113, 117)'}>{t("Add Buddy")}</AddBuddyButtons> : attendee.request_status === "Accepted" ?
                            <AddBuddyButtons background={'rgb(159, 201, 137)'}>{t("Buddy")}</AddBuddyButtons > : attendee.request_status === "Pending" ?
                              <AddBuddyButtons background={'rgb(246, 180, 121)'}>{t("Pending")}</AddBuddyButtons > : ""
                      } */}
                    </AddBuddyButtonContainer>
                  </div>
                </ContentWrapper>
              )) :
              <NoGuests>{t("No registered guests")}</NoGuests>
          }
        </Modal.Body>
        {printAttendees &&
          <CSVList Title={`${Title.replace(/ /g, "_")}_Attendees`} removeCSVData={this.printAction} data={Attendees} headers={headers} />
        }
      </StyledModal>
    )
  }
}

AttendeesModal.propTypes = {
  Attendees: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  Title: PropTypes.string,
  isValidUser: PropTypes.bool,
  sendFriendRequest: PropTypes.func,
  t: PropTypes.func,
  role: PropTypes.string,
  eventType: PropTypes.number,
  eventAttendees: PropTypes.array
};

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});

const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttendeesModal));
