import styled from  'styled-components';
import { Modal } from 'react-bootstrap';

import { AddCalenderButton } from '../MainEvents/styles';

const BoldFont = 'Rubik-Medium';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 25%;
    margin-top: 100px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-body{
      padding: 5px 0 0 0;
      height: 454px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #afcbd3; 
  border-radius: 6px;
}
 
::-webkit-scrollbar-thumb {
  background: #669db7; 
  border-radius: 6px;
}
    }
    @media (max-width: 1000px) {
      width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const CustomTitle = styled(Modal.Title)`
  float: left;
  width: 100%;
  
  > div {
    float: left;
    width: 100%;
    display: flex;
    > div {
      font-size: 12px;
      color: #a9a9a9;
      display: flex;
    }
  
    > span {
      font-size: 18px;
      letter-spacing: 0px;
      font-family: ${BoldFont};
      color: white;
      width: 50%;
      margin: auto;
    }
    .closeImage{
      width: 50%;
      display: flex;
      justify-content: flex-end;
      >img{
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-left: 15px;
        margin: auto;
        margin-right: 0px;
        margin-left: 15px;
      }
      .print{
        width:40px;
        height:40px;
        border-radius: 6px;
        cursor: pointer;
        display:flex;
        background: #FFFFFF1A;
        border-radius:3px;
        >img{
          height: 22px;
          width: 22px;
          display:flex;
          margin:auto;
        }
        >span{
          font-family:Rubik;
          font-weight:400;
          font-size:16px;
          line height:2.5px;
          text-align: center;
          color: #0D4270;
        }
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const PrintListButton = styled(AddCalenderButton)`
  margin-top: 0;
  float: right;
  font-size: 12px;
  > span {
    float: none;
  }

  @media (max-width: 450px) {
    float: left;
    margin-top: 10px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  padding: 5px 15px;
  display: inline-block !important;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    align-items: center;
    .attendeeInfo{
      width: 80%;
      margin: auto;
      >span{
        font-size: 16px;
        color: #005c87;
        font-family: rubik-medium;
        line-height: 20px;
      }
      >p{
        color: #669db7;
        font-size: 14px;
        font-family: rubik;
        margin: 0px;
        line-height: 16px;
      }
    }
    .image {
      width: 60px !important;
      height: 60px !important;
      margin-right: 10px;
      display: flex;
      > img {
        width: 45px!important;
        height: 45px !important;
        border-radius: 3px;
        float: left;
        margin: auto;
      }
    }
  
    > span {
      margin-left: ${({marginLeft}) => marginLeft ? '5px' : '5px'};
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${BoldFont};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      @media(max-width : 1025px) {
        margin-left: ${({marginLeft}) => marginLeft ? '15px' : '5px'};
      }
      @media(max-width : 500px) {
        margin-left: ${({marginLeft}) => marginLeft ? '5px' : '5px'};
      }
    }
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const NoGuests = styled.div`
  width: 100%;
  padding: 30px;
  font-size: 15px;
  font-family: ${BoldFont};
`;

const AddBuddyButtonContainer = styled.div`
  width: 30%;
`;

const AddBuddyButtons = styled.div`
display: flex;
justify-content: center;
width: 100%;
background: ${({background}) => background ? background : 'blue'};
color: white;
font-family: rubik-medium;
font-size: 16px;
padding: 10px;
border: none;
border-radius: 3px;
cursor: pointer;
`;

export { StyledModal, CustomTitle, PrintListButton, ContentWrapper, NoGuests, AddBuddyButtonContainer, AddBuddyButtons };