/* eslint-disable no-undef */
import React from 'react';
import { CSVLink } from "react-csv";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HideElement = styled.div`
  display: block;
`; 

class CSVList extends React.Component {
  componentDidMount() {
    setTimeout(() => this.inputElement.link.click(), 500 );
    setTimeout(() => this.props.removeCSVData(false), 1000);
  }

  render() {
    const { Title, data, headers } = this.props;
    return (
      <HideElement>
        <CSVLink
          data={data}
          headers = {headers}
          filename={`${Title}.csv`}
          target="_blank" 
          ref={(input) => this.inputElement = input}
        />
      </HideElement>
    )
  }
}

CSVList.propTypes = {
  removeCSVData: PropTypes.func,
  Title: PropTypes.string,
  data: PropTypes.array,
  headers: PropTypes.array
}

export default CSVList;